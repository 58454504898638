<template>
	<div>
		<div class="container csc">
			<div class="top-cover">
				<img src="../../assets/order/order_system.png" alt>
			</div>

			<div class="section">
				<el-page-header @back="$router.replace('/order')" content="代运·代购" />
				<div class="title">
					<div>代运 · 代购</div>
					<span>FORWARDING</span>
				</div>

				<div class="rbc">
					<div class="left wrap">
						<el-menu :default-active="menuIndex" class="el-menu-vertical-demo" @select="handleOpen"
							@close="handleClose">
							<el-menu-item :index="index + ''" v-for="(item, index) in menus" :key="index">
								<i :class="item.icon"> </i>
								<span slot="title">{{item.title}}</span>
							</el-menu-item>
						</el-menu>
					</div>

					<div class="right wrap">
						<keep-alive>
						    <router-view v-if="$route.meta.keepAlive"></router-view>
						</keep-alive>
						<router-view v-if="!$route.meta.keepAlive"></router-view>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		// mounted() {
		// 	if (!this.$route.query.index) this.$go('/orderSystem/overbooking')
		// },
		created() {
			let index = this.menus.findIndex(item => item.link == this.$route.path)
			if (index > -1) this.menuIndex = index + ''
		},
		data() {
			return {
				menuIndex: '0',
				menus: [{
					title: '在线下单',
					icon: 'el-icon-thumb',
					link: '/orderSystem/overbooking',
				}, { 
					title: '我的订单',
					icon: 'el-icon-s-order',
					link: '/orderSystem/myOrder',
				},{
					title: '我的余额',
					icon: 'el-icon-wallet',
					link: '/orderSystem/myBalance'
				}, {
					title: '我的地址',
					icon: 'el-icon-location-information',
					link: '/orderSystem/myAddress'
				}, {
					title: '我的购物车',
					icon: 'el-icon-shopping-cart-2',
					link: '/orderSystem/cart'
				}, {
					title: '客户管理',
					icon: 'el-icon-user',
					link: '/orderSystem/customer'
				}],
			}
		},
		methods: {
			handleOpen(key, keyPath) {
				this.menuIndex = key
				
				// if (key == '0') this.$go(this.menus[key].link + '?index=' + this.index)
				this.$go(this.menus[key].link)
			},
			handleClose(key, keyPath) {
				console.log(key, keyPath);
			},
			
		}
	}
</script>

<style lang="scss" scoped>
	.container {
		width: 100%;
		margin: 0 auto;
		background: #F5F5F5;
		position: relative;

		.top-cover {
			position: absolute;
			width: 100%;
			height: 265px;
			background: #1C479D;

			img {
				max-width: 1920px;
				min-width: 1200px;
				width: 100%;
				height: 100%;
				display: block;
				margin: 0 auto;
			}
		}
	}

	.section {
		width: 1200px;
		min-height: 1161px;
		margin: 18px auto 0;
		position: relative;
		z-index: 99;

		.el-page-header {
			color: #fff;
		}

		/deep/ .el-page-header__content {
			color: #fff;
		}

		.title {
			height: 100px;
			padding-top: 30px;
			font-size: 1.5rem;
			color: #fff;
			font-weight: bold;
			text-align: center;
			margin-bottom: 50px;

			span {
				font-size: .5rem;
				font-weight: normal;
			}
		}

		.wrap {
			height: 920px;
			background: #fff;
		}

		.left {
			width: 185px;
			margin-right: 10px;

			.is-active {
				width: 100%;
				color: #fff;
				background: #3F7CF8;
			}

			.el-menu {
				border: none;
			}
		}

		.right {
			width: 1006px;
			padding: 20px 10px;
			overflow: hidden;
		}

		.el-menu-item {
			font-size: 1rem;
		}	
	}

</style>
